import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon, BellIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { SlWallet } from "react-icons/sl";
import { AdminHeaderDropdown } from "../../reuseable/forms/dropdown/dropdown";
import axios from "axios";
import { RiErrorWarningLine } from "react-icons/ri";
import {
  IoIosCheckmarkCircleOutline,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import { SocketContext } from "../../components/socketContext/SocketContext";
import { formatDateWordAndTime } from "../../utils/helper";
import { AdminProfileContext, UserContext } from "../../components/provider/Provider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function AgentHeader() {
  const navigate = useNavigate();
  const { socketData } = useContext(SocketContext);
  const [balance, setBalance] = useState("");
  const [notifyCount, setNotifyCount] = useState("");
  const [notifyList, setNotifyList] = useState([]);
  const { userImage } = useContext(UserContext);

  useEffect(() => {
    const aid = localStorage.getItem("dc");
    const fetchBalance = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY}/wallet/agent${aid}`
        );

        setBalance(res?.data?.data?.[0]?.W_Balance);
        localStorage.setItem("wd", res?.data?.data?.[0]?.W_Id);
      } catch (error) {
        console.log(error);
      }
    };

    if (aid) {
      fetchBalance();
    }
  }, []);

  useEffect(() => {
    const aid = localStorage.getItem("dc");
    const fetchNotifyCount = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY}/notifycnt?id=${aid}&type=agent`
        );
        setNotifyCount(res?.data?.data?.[0]);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchNotifyList = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY}/notifylist?id=${aid}&type=agent`
        );
        setNotifyList(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (aid) {
      fetchNotifyCount();
      fetchNotifyList();
    }
  }, [socketData]);

  const handleNotificationClick = (notification) => {
    if (notification?.N_Type === "wallet") {
      navigate("/agent/wallet", {
        state: { highlightDate: notification.N_CreatedDate },
      });
    }
    else {
      navigate(`/agent/visa/order-update/${notification?.N_TypeId2}`, {
        state: {
          to: notification?.Od_OrderTo,
          from: notification?.Od_OrderFrom,
          type: notification?.OA_Status,
          selectedVisa: {
            value: notification?.Od_ServiceId,
            label: `IND-UAE ${notification?.Sv_name}`,
            id: notification?.Od_ServiceId,
          },
        },
      });
    }
  };

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8  ">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/agent/visa">
                    <img
                      className="h-8 w-auto"
                      src="/assets/AVS.png"
                      alt="Your Company"
                    />
                  </a>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {/* <NavLink
                    to="/agent/dashboard"
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                        : isActive
                        ? "border-indigo-500 inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                    }
                  >
                    Dashboard
                  </NavLink> */}
                  <NavLink
                    to="/agent/visa"
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                        : isActive
                          ? "border-indigo-500 inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
                          : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                    }
                  >
                    Visa
                  </NavLink>
                  {/* <NavLink
                    to="/agent/my-enquiry"
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                        : isActive
                        ? "border-indigo-500 inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                    }
                  >
                    Enquiry
                  </NavLink> */}
                  {/* <NavLink
                    to="/agent/quotation"
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                        : isActive
                        ? "border-indigo-500 inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                    }
                  >
                    Quotation
                  </NavLink> */}
                  <NavLink
                    to="/agent/visa-enquiry"
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                        : isActive
                          ? "border-indigo-500 inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
                          : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                    }
                  >
                    My Enquiry
                  </NavLink>
                  <NavLink
                    to="/agent/order"
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                        : isActive
                          ? "border-indigo-500 inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
                          : "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                    }
                  >
                    Orders
                  </NavLink>
                </div>
              </div>
              <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end"></div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <div className="relative flex-shrink-0 mr-3 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/JeRia9nOgeQDLDsowJQNtq"
                  >
                    <img
                      className="h-8 w-8"
                      src="/assets/community-logo.png"
                      alt="Community logo"
                    />
                  </a>
                </div>
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  aria-hidden="true"
                />

                <div className="relative flex-shrink-0 mr-3 ml-3 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <Menu as="div" className="relative  ml-auto">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Open options</span>
                      <SlWallet className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute z-50 right-0 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/agent/wallet"}
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm font-semibold cursor-pointer leading-6 text-gray-900"
                              )}
                            >
                              <div className="grid space-y-1">
                                <span className="text-xs text-gray-500">
                                  Wallet
                                </span>

                                {balance !== undefined ? (
                                  <>
                                    <p className="text-lg">
                                      ₹ {balance ? balance?.toLocaleString("en-IN") : 0}
                                    </p>
                                    <p className=" underline text-indigo-600 text-xs">
                                      Add Funds
                                    </p>
                                  </>
                                ) : (
                                  <p className="text-xs break-keep text-red-600">
                                    Contact our team
                                  </p>
                                )}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  aria-hidden="true"
                />
                <div className="relative flex-shrink-0 ml-3 mr-3 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <Menu as="div" className="relative  ml-auto">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Open options</span>
                      <BellIcon
                        className="h-6 w-6 relative"
                        aria-hidden="true"
                      />
                      {notifyCount?.count ? (
                        <div className="bg-red-600 px-[4px] pb-[1px] pt-[2px] text-[10px] absolute left-3 top-1 rounded-full flex items-center justify-center">
                          <div className="text-white ">
                            {notifyCount?.count}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute z-50 right-0 cursor-pointer divide-y mt-0.5 w-64 max-h-64 origin-top-right overflow-y-auto rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {!notifyList || notifyList?.length === 0 ? (
                          <div className="text-center font-semibold text-gray-900">
                            No Notifications
                          </div>
                        ) : (
                          notifyList?.map((data) => {
                            return (
                              <Menu.Item key={data?.N_Id}>
                                {({ active }) => (
                                  <a
                                    onClick={() =>
                                      handleNotificationClick(data)
                                    }
                                    // href="#"
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3  py-1 text-sm hover:bg-gray-200 font-semibold truncate leading-6 text-gray-900"
                                    )}
                                  >
                                    <div className="flex items-center">
                                      {data?.N_AlertType === "warning" ? (
                                        <RiErrorWarningLine className="h-5 w-5 text-yellow-400 mr-2" />
                                      ) : data?.N_AlertType === "info" ? (
                                        <IoIosInformationCircleOutline className="h-5 w-5 text-blue-600 mr-2" />
                                      ) : data?.N_AlertType === "danger" ? (
                                        <MdOutlineCancel className="h-5 w-5 text-red-600 mr-2" />
                                      ) : (
                                        <IoIosCheckmarkCircleOutline className="h-5 w-5 text-green-600 mr-2" />
                                      )}

                                      <div>{data?.N_Title}</div>
                                    </div>
                                    <div className="text-[10px] text-gray-500 break-words whitespace-break-spaces leading-tight mt-1">
                                      {data?.N_Message}
                                    </div>
                                    <div className="text-gray-500 text-[8px]">
                                      {formatDateWordAndTime(
                                        data?.N_CreatedDate
                                      )}
                                    </div>
                                  </a>
                                )}
                              </Menu.Item>
                            );
                          })
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  aria-hidden="true"
                />
                {/* <div></div> */}
                <div className="text-gray-800 ml-3 tracking-tight font-semibold text-base">
                  {`${localStorage.getItem("agencyN")} (${localStorage.getItem(
                    "code"
                  )})`}
                </div>

                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>

                      <div className={`rounded-full`}>
                        <img
                          src={!!userImage ? userImage : "/assets/user-icon-image.jpg"}
                          onError={(e) => {
                            e.currentTarget.onerror = null; // prevents 
                            e.currentTarget.src = "/assets/user-icon-image.jpg";
                          }}
                          className="h-8 w-8 text-gray-700 rounded-full"
                          alt="user-img"
                        />
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={"/agent/my-profile"}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              localStorage.clear();
                              window.location.href = "//travelofy.com/";
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                          >
                            Sign out
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
              <NavLink
                to="/agent/visa"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                    : isActive
                      ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                      : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                }
              >
                Visa
              </NavLink>
              <NavLink
                to="/agent/visa-enquiry"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                    : isActive
                      ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                      : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                }
              >
                My Enquiry
              </NavLink>
              {/* <NavLink
                as="a"
                to="/agent/my-enquiry"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                    : isActive
                    ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                }
              >
                Enquiry
              </NavLink> */}
              <NavLink
                as="a"
                to="/agent/order"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                    : isActive
                      ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                      : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                }
              >
                Orders
              </NavLink>
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    src={!!userImage ? userImage : "/assets/user-icon-image.jpg"}
                    onError={(e) => {
                      e.currentTarget.onerror = null; // prevents 
                      e.currentTarget.src = "/assets/user-icon-image.jpg";
                    }}
                    className="h-8 w-8 text-gray-700 rounded-full"
                    alt="user-img"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {`${localStorage.getItem("agencyN")} (${localStorage.getItem(
                      "code"
                    )})`}
                  </div>
                </div>
                <div
                  className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <div className="flex gap-x-3">
                    <Menu as="div" className="relative  ml-auto">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <SlWallet className="h-6 w-6" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-50 right-0 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={"/agent/wallet"}
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm font-semibold cursor-pointer leading-6 text-gray-900"
                                )}
                              >
                                <div className="grid space-y-1">
                                  <span className="text-xs text-gray-500">
                                    Wallet
                                  </span>

                                  {balance !== undefined ? (
                                    <>
                                      <p className="text-lg">
                                        ₹ {balance ? balance?.toLocaleString("en-IN") : 0}
                                      </p>
                                      <p className=" underline text-indigo-600 text-xs">
                                        Add Funds
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-xs break-keep text-red-600">
                                      Contact our team
                                    </p>
                                  )}
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <div
                      className="block lg:hidden h-6 w-px bg-gray-200"
                    />
                    <Menu as="div" className="relative  ml-auto">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <BellIcon
                          className="h-6 w-6 relative"
                          aria-hidden="true"
                        />
                        {notifyCount?.count ? (
                          <div className="bg-red-600 px-[4px] pb-[1px] pt-[2px] text-[10px] absolute left-3 top-1 rounded-full flex items-center justify-center">
                            <div className="text-white ">
                              {notifyCount?.count}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-50 right-0 cursor-pointer divide-y mt-0.5 w-64 max-h-64 origin-top-right overflow-y-auto rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          {!notifyList || notifyList?.length === 0 ? (
                            <div className="text-center font-semibold text-gray-900">
                              No Notifications
                            </div>
                          ) : (
                            notifyList?.map((data) => {
                              return (
                                <Menu.Item key={data?.N_Id} >
                                  {({ active }) => (
                                    <a
                                      onClick={() =>
                                        handleNotificationClick(data)
                                      }
                                      // href="#"
                                      className={classNames(
                                        active ? "bg-gray-50" : "",
                                        "block px-3  py-1 text-sm hover:bg-gray-200 font-semibold truncate leading-6 text-gray-900"
                                      )}
                                    >
                                      <div className="flex items-center">
                                        {data?.N_AlertType === "warning" ? (
                                          <RiErrorWarningLine className="h-5 w-5 text-yellow-400 mr-2" />
                                        ) : data?.N_AlertType === "info" ? (
                                          <IoIosInformationCircleOutline className="h-5 w-5 text-blue-600 mr-2" />
                                        ) : data?.N_AlertType === "danger" ? (
                                          <MdOutlineCancel className="h-5 w-5 text-red-600 mr-2" />
                                        ) : (
                                          <IoIosCheckmarkCircleOutline className="h-5 w-5 text-green-600 mr-2" />
                                        )}

                                        <div>{data?.N_Title}</div>
                                      </div>
                                      <div className="text-[10px] text-gray-500 break-words whitespace-break-spaces leading-tight mt-1">
                                        {data?.N_Message}
                                      </div>
                                      <div className="text-gray-500 text-[8px]">
                                        {formatDateWordAndTime(
                                          data?.N_CreatedDate
                                        )}
                                      </div>
                                    </a>
                                  )}
                                </Menu.Item>
                              );
                            })
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                </div>
              </div>
              <div className="mt-3 space-y-1">
                <NavLink
                  to={"/agent/my-profile"}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Your Profile
                </NavLink>
                <Disclosure.Button
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "//travelofy.com/";
                  }}
                  className="block px-4 py-2 w-full text-left text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export function AdminHeader({
  setSidebarOpen,
  bgSidebarOpen,
  setBgSidebarOpen,
}) {
  const navigate = useNavigate();
  const { adminImage } = useContext(AdminProfileContext);
  const roles = JSON.parse(localStorage?.getItem("roles"));
  const [selectRole, setSelectRole] = useState(
    JSON.parse(localStorage.getItem("currentRole"))
  );



  const logoutHandler = async () => {
    const uid = localStorage.getItem("userId");
    localStorage.clear();
    const res = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY}/admin/logout${uid}`
    );

    if (res?.data?.success) {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="  -m-2.5 p-2.5 text-gray-700 hidden lg:block"
          onClick={() => setBgSidebarOpen(!bgSidebarOpen)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200" aria-hidden="true" />

        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
            />
          </form>
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <div className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">View notifications</span>
              <Menu as="div" className="relative  ml-auto">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Open options</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-50 right-0 cursor-pointer mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          // href="#"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block  px-3 py-1 text-sm font-semibold leading-6 text-gray-900"
                          )}
                        >
                          Notifications
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          // href="#"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                        >
                          Coming soon!!!
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />
            {roles?.length > 1 ? (
              <AdminHeaderDropdown
                options={roles}
                selected={selectRole}
                setSelected={setSelectRole}
              />
            ) : (
              <div>
                <div className="text-xs text-gray-500">role</div>
                <div className="text-sm">{selectRole?.label}</div>
              </div>
            )}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />

            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full object-fill bg-gray-50"
                  src={!!adminImage ? adminImage : "/assets/user-icon-image.jpg"}
                  onError={(e) => {
                    e.currentTarget.onerror = null; // prevents 
                    e.currentTarget.src = "/assets/user-icon-image.jpg";
                  }}
                  alt="profile-picture"
                />
                <span className="hidden lg:flex lg:items-center">
                  <span
                    className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                  >
                    {localStorage.getItem("username")}
                  </span>
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={"/my-profile"}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900"
                        )}
                      >
                        Your profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      onClick={logoutHandler}
                      className={
                        " hover:bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-900"
                      }
                    >
                      Log out
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
